import Vue from 'vue'
import Vuex from 'vuex'
import VueLocalStorage from 'vue-localstorage'

Vue.use(Vuex)
Vue.use(VueLocalStorage)

export default new Vuex.Store({
    state: {
        mode: "upload",
        token: null,
        cookieConsent: null,
        baseUrl: "",
        emailReset: "",
        pwa: null,

        start: false,
        terminalConnectorRunning: null,
        attemptedConnectorRegister: false,

        infos: {
            name: "",
            email: "",

            siteNotice: "",
            dataPrivacy: "",

            logo: "",

            pictures: [],

            articles: [
                {
                    id: "",
                    paper: "",
                    size: "",
                    price: 0,
                },
            ],

            maxPicturesPerOrder: 0,
            fee: 0.0,
            feeDiscount: 0.0,

            maxFileSizeInMB: 0,
            allowedFileMimeTypes: [],
            allowedFileExtensions: [],

            transfer: {
                fixedScreensavers: [],
            }
        },
    },
    mutations: {},
    actions: {},
    modules: {}
})
