<template>
    <span
        :class="disabled ? 'module-disabled-struct' : ''"
        :uk-tooltip="
            disabled
                ? 'title:' + $t('x.module.disabled.hint', {x: $t('dTransfer')})
                : ''
        ">
        <span
            :class="
                disabled ? 'module-disabled uk-height-1-1' : 'uk-height-1-1'
            ">
            <LoadingStruct :is-loading="loading" is-animation-disabled>
                <LabelWithError :label="$t('used.storage.size')" is-bold />
                <div class="uk-margin-small-bottom" />

                <Translate
                    :parameter="{
                        used: getParsedBytes(stats.sizeUsageInBytes),
                        total: getParsedBytes(stats.sizeLimitInBytes),
                    }"
                    translation-key="x.of.total.x.used" />
                <progress
                    :value="stats.sizeUsedInPercentage"
                    class="uk-progress"
                    max="100" />

                <InfoBox v-if="isSizeLimitReached" icon="warning" mode="danger">
                    <Translate translation-key="storage.size.limit.reached" />
                </InfoBox>

                <div class="uk-margin-medium-top" />

                <div class="uk-margin-top" />
                <div v-if="!computedList || computedList.length === 0">
                    {{ $t('none.x', {x: $t('files')}) }}
                </div>
                <div v-else class="uk-overflow-auto">
                    <table
                        class="uk-table uk-table-middle uk-table-divider uk-table-hover">
                        <thead>
                            <tr>
                                <th class="uk-table-shrink">
                                    {{ $t('name') }}
                                </th>



                                <th class="uk-table-shrink">
                                    {{ $t('expires.at') }}
                                </th>

                                <th class="uk-table-shrink">
                                    {{ $t('size') }}
                                </th>
                                <th class="uk-table-shrink">
                                    {{ $t('created') }}
                                </th>

                                <th class="uk-table-shrink">
                                    {{ $t('sent.to') }}
                                </th>

                                <th class="uk-table-shrink">
                                    {{ $t('message') }}
                                </th>

                                 <th class="uk-table-shrink">
                                    {{ $t('link') }}
                                </th>

                                <th class="width-action" />
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="(gallery, key) in computedList"
                                :key="'transfer-' + key"
                                class="uk-text-nowrap">
                                <td>
                                    <Icon
                                        class="uk-margin-small-right"
                                        icon="tag" />
                                    {{ gallery.name }}
                                </td>

                                <td>
                                    <Icon
                                        class="uk-margin-small-right"
                                        icon="trash" />
                                    {{
                                        getDaysLeftByDateString(
                                            gallery.expiresAt,
                                            false
                                        )
                                    }}
                                </td>

                                <td>
                                    <Icon
                                        class="uk-margin-small-right"
                                        icon="database" />
                                    {{ getParsedBytes(gallery.sizeInBytes) }}
                                </td>

                                <td>
                                    <Icon
                                        class="uk-margin-small-right"
                                        icon="clock" />
                                    <Translate translation-key="for" />
                                    {{ gallery.expiresInDays }}
                                    <Translate translation-key="days" />
                                    <Translate translation-key="on" />
                                    <span
                                        :uk-tooltip="
                                            'title:' +
                                            getFormattedDateTimeByDateString(
                                                gallery.createdAt
                                            )
                                        ">
                                        {{
                                            getFormattedDateByDateString(
                                                gallery.createdAt
                                            )
                                        }}
                                    </span>
                                </td>

                                <td>
                                    <div v-if="gallery.email">
                                        <Icon
                                            class="uk-margin-small-right"
                                            icon="mail" />
                                        {{ gallery.email }}
                                    </div>
                                </td>


                                <td>
                                    <span v-if="gallery.message"
                                        v-on:click="showMessage(gallery)"
                                        :uk-tooltip="
                                            'title:' + $t('show.x', {x:$t('message')})
                                        ">
                                        <Button mode="default">
                                            <Icon icon="comment" />
                                        </Button>
                                    </span>
                                </td>

                                     <td>
                                    <span
                                        v-on:click="showFile(gallery)"
                                        :uk-tooltip="
                                            'title:' + $t('copy.to.clipboard')
                                        ">
                                        <Button mode="primary">
                                            <Icon icon="copy-clipboard" />
                                        </Button>
                                    </span>
                                </td>

                                <td>
                                    <span
                                        v-on:click="deleteAction(gallery)"
                                        :uk-tooltip="
                                            'title:' + $t('delete.now')
                                        ">
                                        <Button mode="danger">
                                            <Icon :ratio="1" icon="trash" />
                                        </Button>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </LoadingStruct>

            <Modal
                :id="modalIdDelete"
                :title="
                    $t('x.delete', {
                        x: $t('transfer'),
                    })
                ">
                <div class="uk-text-center" v-if="selected">
                    <Translate translation-key="transfer.delete.now.message"
                    :parameter="{x:selected.name}"/>
                </div>

                <div
                    class="uk-flex uk-flex-center uk-margin-medium-top"
                    uk-grid>
                    <div>
                        <Button
                            :text="$t('cancel')"
                            class="uk-modal-close"
                            is-large
                            is-max
                            is-min-width
                            mode="default" />
                    </div>
                    <div>
                        <Button
                            :action="actionDeleteConfirmed"
                            :is-loading="deleteLoading"
                            :text="
                                $t('x.delete', {
                                    x: $t('transfer'),
                                })
                            "
                            has-action
                            is-large
                            is-max
                            is-min-width
                            mode="danger" />
                    </div>
                </div>

                <div v-if="deleteError" class="uk-margin-medium-bottom" />
                <ErrorMessage :error="deleteError" />

                <div v-if="deleteSuccess" class="uk-margin-medium-bottom" />
                <SuccessMessage
                    :message="$t('x.changes.saved', {x: $t('public')})"
                    :success="deleteSuccess" />
            </Modal>



            <Modal
                :id="modalIdMessage"
                :title="
                   (selected ? selected.name : '')+' '+ $t('message')
                ">
                <div class="uk-text-center" v-if="selected">
                    {{selected.message}}
                </div>

                <div class="uk-margin-medium-top"/>
                 <Button
                     :text="$t('close')"
                     class="uk-modal-close"
                     is-large
                     is-max
                     is-min-width
                     mode="default" />

            </Modal>
        </span>
    </span>
</template>

<script>
import Button from '@/components/generic/Button.vue';
import axios from 'axios';
import LoadingStruct from '@/views/LoadingStruct.vue';
import Icon from '@/components/generic/Icon.vue';
import LabelWithError from '@/components/generic/LabelWithError.vue';
import Translate from '@/components/Translate.vue';
import InfoBox from '@/components/generic/InfoBox.vue';
import Modal from '@/components/generic/Modal.vue';
import UIkit from 'uikit';
import ErrorMessage from '@/components/generic/ErrorMessage.vue';
import SuccessMessage from '@/components/generic/SuccessMessage.vue';

export default {
    name: 'TransfersForm',
    components: {
        SuccessMessage,
        ErrorMessage,
        Modal,
        InfoBox,
        Translate,
        LabelWithError,
        Icon,
        LoadingStruct,
        Button,
    },
    props: {
        disabled: Boolean,
        articleLists: Array,
        settings: Object,
    },
    data() {
        return {
            // ids
            modalIdDelete: 'modal-transfer-delete',
            modalIdMessage: 'modal-transfer-message',

            // selected
            hoveredId: null,
            selected: null,

            // data
            stats: {
                sizeUsageInBytes: 0,
                sizeLimitInBytes: 0,
                sizeUsedInPercentage: 0,
            },
            error: null,
            loading: true,

            // delete
            deleteLoading: false,
            deleteSuccess: false,
            deleteError: null,
        };
    },
    computed: {
        isSizeLimitReached() {
            return this.stats.sizeUsedInPercentage >= 100;
        },
        computedList() {
            let list = this.settings.transfers;

            if (!list) {
                return [];
            }

            return list;
        },
    },
    created() {
        if(this.disabled) {
            this.loading= false
            return
        }

        this.actionGetStats();
    },
    methods: {
        showMessage(gallery) {
            this.selected = gallery;
            UIkit.modal('#' + this.modalIdMessage).show();
        },
        showFile(gallery) {
            const link =
                window.location.origin +
                '/' +
                this.$i18n.locale +
                '/transfers/' +
                gallery.token;

            this.copyToClipboard(link);
        },
        deleteAction(gallery) {
            this.selected = gallery;
            UIkit.modal('#' + this.modalIdDelete).show();
        },
        actionGetStats() {
            let http = axios.create();

            http.get(this.apiUrl + '/manage-transfers/stats')
                .then((response) => {
                    this.stats = response.data;
                    this.stats.sizeUsedInPercentage =
                        (this.stats.sizeUsageInBytes /
                            this.stats.sizeLimitInBytes) *
                        100.0;

                    this.error = null;
                    this.loading = false;
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    this.error = e;
                    this.loading = false;
                });
        },
        actionDeleteConfirmed() {
            this.deleteLoading = true;

            let http = axios.create();
            http.delete(this.apiUrl + '/manage-transfers/' + this.selected.id)
                .then(() => {
                    UIkit.modal('#' + this.modalIdDelete).hide();

                    UIkit.notification({
                        message: this.$t('transfer.deleted.successfully'),
                        status: 'info',
                        pos: 'top-center',
                        timeout: this.notificationTimeout,
                    });

                    this.deleteLoading = false;

                    this.$emit('updated');
                    this.actionGetStats(false);
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    UIkit.notification({
                        message: this.$t('unexpected.error'),
                        status: 'error',
                        pos: 'top-center',
                        timeout: this.notificationTimeout,
                    });

                    this.deleteLoading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.width-action {
    width: 70px;
}

.generated-title {
    text-transform: uppercase;
    font-size: 48px;
    font-weight: 500;
}

.thumbnail-height {
    height: 100px;
}

.uk-table th {
    white-space: nowrap;
}

.uk-progress {
    border-radius: 0 !important;
}

.uk-progress::-webkit-progress-value {
    background-color: $theme-background !important;
}

.uk-progress::-moz-progress-bar {
    background-color: $theme-background !important;
}

.dragover {
    border-color: $info-color;
}

.folder-name {
    margin-top: 3px;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.folder-sub {
    font-size: 15px;
}

.favicon-folder {
    max-width: 100px;
    max-height: 100px;
}
</style>
